<template>
  <div v-loading="isLoading" class="d-flex flex-column form-container w-50">
    <ProductDetails :product="product" />
    <ProductCatalogForm
      v-if="product"
      :product="product"
      :catalog-ids="catalogIds"
      :catalogs="catalogs"
      @on-catalog-change="(catalogs) => $emit('on-catalog-change', catalogs)"
    />
    <ProductCategoryForm
      :product="product"
      :category-trees="categoryTrees"
      :has-error="categoryError"
      @on-category-select="(selection) => $emit('on-category-select', selection)"
    />
    <ProductBOMForm :product="product" :errors="bomErrors" @on-bom-change="(bom) => $emit('on-bom-change', bom)" />
  </div>
</template>
<script>
import ProductDetails from './ProductDetails.vue';
import ProductCategoryForm from './ProductCategoryForm.vue';
import ProductBOMForm from './ProductBOMForm.vue';
import ProductCatalogForm from './ProductCatalogForm.vue';
import { useFetchAll } from '@/modules/purchase-management/compositions/useFetchAll';
import { useCatalogs } from '@/modules/catalog/compositions/useCatalog';
import { computed } from 'vue';

const CATALOG_LIMIT = 500;

export default {
  name: 'DefineProductForm',
  components: { ProductDetails, ProductCategoryForm, ProductBOMForm, ProductCatalogForm },
  props: {
    product: { type: Object, default: undefined },
    loading: { type: Boolean, default: false },
    categoryError: { type: Boolean, default: false },
    bomErrors: { type: Array, required: true },
    categoryTrees: { type: Array, default: () => [] },
    catalogIds: { type: Array, default: () => [] },
  },
  emits: ['on-category-select', 'on-bom-change', 'on-catalog-change'],
  setup(props) {
    const businessId = computed(() => props.product?.business?.id);

    const { loading: catalogsLoading, allResults: catalogs } = useFetchAll({
      limit: CATALOG_LIMIT,
      dataSelector: 'catalogs',
      hook: useCatalogs,
      variables: { businessId },
      options: () => ({ enabled: !!businessId.value }),
    });

    const isLoading = computed(() => props.loading || catalogsLoading.value);

    return {
      isLoading,
      catalogs,
    };
  },
};
</script>
<style lang="scss" scoped>
.form-container {
  gap: 2.5rem;
}
</style>
