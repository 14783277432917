<template>
  <Table border rounded :columns="columns" :data="data" />
</template>
<script>
import { computed, getCurrentInstance } from 'vue';

import { Table } from '@/modules/core';

import { BOM_HEADERS, getBomTableColumns } from './mapProductsTaskColumns';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';

export default {
  name: 'BomTable',
  components: { Table },
  props: { bom: { type: Object, default: null } },
  emits: [''],
  setup(props) {
    const root = getCurrentInstance().proxy;
    const columns = getBomTableColumns();

    const getTextByUom = (bom) => {
      let text = '';
      const isPlural = bom.quantity > 1;
      if (isPlural) {
        text += `${bom.quantity} `;
      }
      text += `${root.$t(`tasks.defineProductTask.bomForm.units.${isPlural ? `plural_${bom.uom}` : bom.uom}`)}`;

      return text;
    };

    const data = computed(() => {
      const rowData = {
        [BOM_HEADERS.BILLING_UNIT]: MISSING_DATA_TEXT,
        [BOM_HEADERS.FIRST_BOM]: MISSING_DATA_TEXT,
        [BOM_HEADERS.SECOND_BOM]: MISSING_DATA_TEXT,
        [BOM_HEADERS.THIRD_BOM]: MISSING_DATA_TEXT,
      };
      let currentBom = props.bom;
      let index = 0;
      while (currentBom) {
        if (currentBom?.billingUnit) {
          rowData[BOM_HEADERS.BILLING_UNIT] = currentBom.uom;
        }
        if (index === 0) {
          rowData[BOM_HEADERS.FIRST_BOM] = getTextByUom(currentBom);
        }
        if (index === 1) {
          rowData[BOM_HEADERS.SECOND_BOM] = getTextByUom(currentBom);
        }
        if (index === 2) {
          rowData[BOM_HEADERS.THIRD_BOM] = getTextByUom(currentBom);
        }

        currentBom = currentBom?.bom;
        index += 1;
      }
      return [rowData];
    });

    return {
      data,
      columns,
    };
  },
};
</script>
<style lang="scss" scoped></style>
