<template>
  <div>
    <SingularTaskLayout
      :title="$t('tasks.defineProductTask.title')"
      :task="currentTask"
      :is-loading-task="isLoadingTask"
      :validate-task-before-complete-hook="validateTaskHook"
      @complete-task="onTaskComplete"
      @skip-task="onTaskSkip"
    >
      <template #content>
        <div class="w-100 h-100 d-flex task-container">
          <DefineProductForm
            :product="mergedProductData"
            :loading="productLoading || categoriesLoading"
            :category-trees="categoryTrees"
            :category-error="categoryError"
            :bom-errors="bomErrors"
            :catalog-ids="updatedTaskProductData.catalogIds"
            @on-category-select="onCategorySelect"
            @on-bom-change="onBomChange"
            @on-catalog-change="onCatalogChange"
          />
          <div v-loading="documentLoading || fallbackDocLoading" class="d-flex flex-column gap-4 w-50 doc-container">
            <p class="document-title">{{ $t('tasks.defineProductTask.originDocument') }}</p>
            <FileViewer
              v-if="documentFilePathUrl || fallBackFilePathUrl"
              :url="documentFilePathUrl || fallBackFilePathUrl"
            />
          </div>
        </div>
      </template>
      <template #play-mode-indicator>
        <slot name="play-mode-indicator"></slot>
      </template>
    </SingularTaskLayout>
  </div>
</template>
<script>
import { ref, computed, reactive, watch, nextTick } from 'vue';
import { FileViewer } from '@clarityo/ui-components';
import SingularTaskLayout from '../../../tasks/task/components/SingularTaskLayout';
import { useProductUpdateNew } from '../../compositions/productUpdateNew';
import { useProductNew } from '@/modules/product/compositions/product';
import { useCategories } from '@/modules/product/compositions/categories';
import DefineProductForm from './DefineProductForm.vue';
import { useProductOriginDocument } from '../../compositions/order/useProductOriginDocument';
import { useFallbackDocumentsForDefineProduct } from '../../compositions/document/useFallbackDocumentsForDefineProduct';
import { BOM_ERRORS } from './ProductBOMForm.vue';
import { useCreateProductInCatalog } from '../../../catalog/compositions/useCreateProductInCatalog';
import { useDeleteProductInCatalog } from '../../../catalog/compositions/useDeleteProductInCatalog';
import { merge } from 'lodash';
import { usePatchTaskNew2 } from '@/modules/tasks';
import { ANALYTICS_EVENT_TYPES } from '../../../../analytics/types';
import { useAnalytics } from '../../../../analytics/compositions/useAnalytics';

export default {
  name: 'DefineProductTask',
  components: { SingularTaskLayout, DefineProductForm, FileViewer },
  props: {
    task: { type: Object, required: true },
    isLoadingTask: { type: Boolean, required: true },
  },
  emits: ['on-task-complete', 'on-task-skip'],
  setup(props, { emit }) {
    const bom = ref();
    const categoryId = ref();
    const bomErrors = ref([]);
    const catalogsToAdd = ref([]);
    const catalogsToRemove = ref([]);
    const categoryError = ref(false);
    const initialSyncDone = ref(false);
    const productId = ref(null);

    const initialTaskProductData = {
      bom: null,
      categoryId: null,
      catalogIds: [],
    };

    const updatedTaskProductData = reactive({ ...initialTaskProductData });

    const { mutate: updateProduct } = useProductUpdateNew();
    const { patchTask } = usePatchTaskNew2();

    const { logEvent } = useAnalytics();

    const taskType = computed(() =>
      props?.task?.type && props?.task?.domain ? `${props.task.type}_${props.task.domain}` : ''
    );

    const currentTask = computed(() => props.task);

    const { product, loading: productLoading } = useProductNew(productId);
    const { documentFilePathUrl, loading: documentLoading } = useProductOriginDocument(productId);
    const fallbackEnabled = computed(() => {
      const finishedRetrievingOrderOriginAndIsEmpty = documentFilePathUrl.value === '' && !documentLoading.value;
      const hasProduct = !!product.value && !productLoading.value;

      return finishedRetrievingOrderOriginAndIsEmpty && hasProduct;
    });
    const { document: fallbackDoc, loading: fallbackDocLoading } = useFallbackDocumentsForDefineProduct(
      product,
      fallbackEnabled
    );
    const { categoryTrees, loading: categoriesLoading } = useCategories();

    const fallBackFilePathUrl = computed(() => fallbackDoc.value?.filePathUrl);

    const { createProductInCatalog } = useCreateProductInCatalog();
    const { deleteProductInCatalog } = useDeleteProductInCatalog();

    const logOnStartEndEventsAnalytics = (isStart = false) => {
      logEvent(ANALYTICS_EVENT_TYPES.TASKS[isStart ? 'STARTED' : 'ENDED'], {
        taskId: currentTask.value.id,
        taskType: taskType.value,
        product: {
          ...product.value,
        },
      });
    };

    watch(
      currentTask,
      (latestTask) => {
        const updatedProductId = latestTask?.data?.productId;

        if (productId.value !== updatedProductId) {
          productId.value = updatedProductId;
          updatedTaskProductData.bom = initialTaskProductData.bom;
          updatedTaskProductData.categoryId = initialTaskProductData.categoryId;
          updatedTaskProductData.catalogIds = initialTaskProductData.catalogIds;

          logOnStartEndEventsAnalytics(true);
        }
      },
      { deep: true, immediate: true }
    );

    const mergedProductData = computed(() => {
      if (!product.value || !updatedTaskProductData) {
        return product.value;
      }
      return merge({}, product.value, updatedTaskProductData);
    });

    const getRelevantProductDataProps = (productData = {}) => {
      const { bom, categoryId, catalogIds = [] } = productData;
      return { bom, categoryId, catalogIds };
    };

    const getBomArrFromObj = (bom) => {
      const result = [];
      let currentBom = bom;

      while (currentBom) {
        const { quantity, uom, billingUnit } = currentBom;
        result.push({ quantity, unit: uom, billingUnit });
        currentBom = currentBom.bom;
      }

      return result.reverse();
    };

    const getBomObjFromArr = (bom) => {
      let bomToUpdate = null;
      for (let i = bom.length - 1; i >= 0; i--) {
        const item = bom[i];
        const itemToAppend = {
          quantity: Number(item.quantity),
          ...(item.billingUnit === true ? { billingUnit: item.billingUnit } : {}),
          uom: item.unit,
        };
        if (bomToUpdate === null) {
          bomToUpdate = itemToAppend;
        } else {
          bomToUpdate = { ...itemToAppend, bom: bomToUpdate };
        }
      }
      return bomToUpdate;
    };

    watch(
      updatedTaskProductData,
      (latestProductData) => {
        if (initialSyncDone.value) {
          const updatedProductData = {
            ...(currentTask.value.data || {}),
            ...getRelevantProductDataProps(latestProductData),
          };

          patchTask({ taskId: currentTask.value.id, patchParams: { data: updatedProductData } });
        }
      },
      { deep: true }
    );

    watch(
      [currentTask, product],
      ([latestTask, latestProduct]) => {
        if (!initialSyncDone.value) {
          if (latestTask?.data) {
            Object.assign(updatedTaskProductData, getRelevantProductDataProps(latestTask?.data));
          }

          if (latestTask?.data?.bom) {
            bom.value = getBomArrFromObj(latestTask.data.bom);
          } else if (latestProduct?.bom) {
            bom.value = getBomArrFromObj(latestProduct.bom);
          }

          if (latestTask?.data) {
            nextTick(() => {
              initialSyncDone.value = true;
            });
          }
        }
      },
      { immediate: true, deep: true }
    );

    const validateTaskHook = async () => {
      const errors = [];

      let newBomErrors = [];
      if (!bom.value) {
        errors.push('No bom provided');
        newBomErrors.push({ error: BOM_ERRORS.NO_BOM });
      } else {
        let hasQuantityError = false;
        bom.value.forEach((eachBom, index) => {
          if (isNaN(Number(eachBom.quantity)) || eachBom.quantity === '') {
            newBomErrors.push({ index, error: BOM_ERRORS.MISSING_QUANTITY });
            hasQuantityError = true;
          }
        });

        if (hasQuantityError) {
          errors.push('Missing quantity on BOMs');
        }

        const hasBillingUnit = bom.value.some(({ billingUnit }) => billingUnit === true);
        if (!hasBillingUnit) {
          errors.push('Please mark at least one of them BOMs as a billing unit');
          newBomErrors.push({ error: BOM_ERRORS.NO_BILLING_UNIT });
        }
      }

      if (!newBomErrors.length) {
        const bomToUpdate = getBomObjFromArr(bom.value);

        submitCatalogChanges();
        updateProduct({
          id: productId.value,
          productData: {
            name: product.value.name,
            sku: product.value.sku,
            businessId: product.value.business.id,
            categoryId: categoryId.value,
            bom: bomToUpdate,
          },
        });
      } else {
        logEvent(ANALYTICS_EVENT_TYPES.TASKS.VALIDATION_ERRORS, {
          taskId: currentTask.value.id,
          taskType: taskType.value,
          product: {
            ...product.value,
          },
          validationErrors: errors,
        });
      }
      bomErrors.value = newBomErrors;

      return {
        errors,
        warnings: [],
      };
    };

    const resetUpdatedTaskProductData = () => {
      updatedTaskProductData.bom = initialTaskProductData.bom;
      updatedTaskProductData.categoryId = initialTaskProductData.categoryId;
      updatedTaskProductData.catalogIds = initialTaskProductData.catalogIds;
    };

    const onTaskComplete = (taskId, taskData) => {
      emit('on-task-complete');
      resetUpdatedTaskProductData();

      const { reviewRequired, activeAt, data, taskType, analyticsEventName } = taskData;

      logEvent(analyticsEventName, {
        taskId,
        reviewRequired,
        activeAt,
        taskType,
        data,
        product: {
          ...product.value,
        },
      });

      logOnStartEndEventsAnalytics();
    };

    const onTaskSkip = (taskId, taskData) => {
      emit('on-task-skip');
      resetUpdatedTaskProductData();

      const { reviewRequired, activeAt, data, taskType, analyticsEventName } = taskData;

      logEvent(analyticsEventName, {
        taskId,
        reviewRequired,
        activeAt,
        taskType,
        data,
        product: {
          ...product.value,
        },
      });

      logOnStartEndEventsAnalytics();
    };

    const onCategorySelect = (selection) => {
      categoryId.value = selection;
      categoryError.value = false;
      updatedTaskProductData.categoryId = selection;
    };

    const submitCatalogChanges = () => {
      catalogsToAdd.value.forEach((id) => {
        createProductInCatalog({ id, params: { productId: productId.value } });
      });

      catalogsToRemove.value.forEach((id) => {
        deleteProductInCatalog({ id, params: { productId: productId.value } });
      });
    };

    const onBomChange = (newBom) => {
      bom.value = newBom;
      bomErrors.value = [];
      updatedTaskProductData.bom = getBomObjFromArr(newBom);
    };

    const onCatalogChange = ({
      catalogsToAdd: newCatalogsToAdd,
      catalogsToRemove: newCatalogsToRemove,
      productCatalogs = [],
    }) => {
      catalogsToAdd.value = newCatalogsToAdd;
      catalogsToRemove.value = newCatalogsToRemove;

      const updatedCatalogIds = [...productCatalogs, ...updatedTaskProductData.catalogIds];

      updatedTaskProductData.catalogIds = [
        ...new Set([...updatedCatalogIds.filter((id) => !newCatalogsToRemove.includes(id)), ...newCatalogsToAdd]),
      ];
    };

    return {
      product,
      bomErrors,
      productId,
      currentTask,
      categoryTrees,
      categoryError,
      productLoading,
      documentLoading,
      categoriesLoading,
      fallbackDocLoading,
      fallBackFilePathUrl,
      documentFilePathUrl,
      onBomChange,
      onCatalogChange,
      validateTaskHook,
      onCategorySelect,
      onTaskComplete,
      onTaskSkip,
      mergedProductData,
      updatedTaskProductData,
    };
  },
};
</script>
<style lang="scss" scoped>
.task-container {
  gap: 3rem;
}
.doc-container {
  min-height: 60vh;
}
.document-title {
  font-size: 1.25rem;
  font-weight: 700;
}
</style>
